import React from "react";
import { Route, Switch } from "react-router-dom";
import ActivityView from "@c/activity-view/ActivityView";
import TagView from "@c/tag-view/TagView";
import MainContent from "@c/users-view/MainContent";
import CaseStudyView from "../components/case-study-view/CaseStudyView";
import SideMenu from "@g/side-menu/SideMenu";
import Header from "@g/header/Header";
import GlobalModal from "@g/GlobalModal";
import { ContentContainer, MainContainer } from "@sc/custom.styled";
import ReadingChallengeView from "@c/reading-challenge-view/ReadingChallengeView";
import ProductView from "@c/product-view/ProductView";
import AssessmentsView from "@c/assessments-view/AssessmentsView";
import InviteCodeView from "@c/invite-code-view/InviteCodeView";
import FliveCodeView from "@c/flive-code-view/FliveCodeView";
import LeaderBoardView from "@c/leader-board-view";
import NotificationsView from "@c/notifications-view/NotificationsView";
import StoryView from "@c/story-view/StoryView";
import NewsFreedsView from "@c/news-freeds-view/NewsFreedsView";
import PaymentsView from "@c/payments-view/PaymentsView";
import ExperiencesView from "@c/experiences-view/ExperiencesView";
import Welcome from "@c/welcome/Welcome";
import SchoolCodeView from "@c/school-code-view/SchoolCodeView";
import Quiz2View from "@c/quiz2-view/Quiz2View";
import FreadomFuture from "@c/freadom-future-view/FreadomFutureView";
import SchoolGuidedPath from "@c/school-guided-path-view/SchoolGuidedPathView";

export default function({ match, location }) {
  return (
    <MainContainer>
      <SideMenu />
      <ContentContainer>
        <Header title={location} />

        <Switch>
          <Route
            path="/notifications/:type"
            render={(props) => <NotificationsView {...props} />}
          />
          <Route component={MainContent} path={"/users"} />
          <Route component={ActivityView} path="/activities" />
          <Route component={CaseStudyView} path="/case-studies" />
          <Route component={ReadingChallengeView} path="/reading-challenge" />
          <Route component={AssessmentsView} path="/assessments" />
          <Route component={InviteCodeView} path="/invite-code" />
          <Route component={SchoolCodeView} path="/school-code" />
          <Route component={FliveCodeView} path="/flive-code" />
          <Route component={LeaderBoardView} path="/leader-board" />
          <Route component={TagView} path="/tags" />
          <Route component={Quiz2View} path="/quiz2" />

          <Route
            path={"/stories/:gradeLevel/:level"}
            render={(props) => <StoryView {...props} />}
          />
          <Route
            path={"/payments/:type"}
            render={(props) => <PaymentsView {...props} />}
          />
          <Route
            path={"/experiences/:category"}
            render={(props) => <ExperiencesView {...props} />}
          />
          <Route
            path={"/news-freads/:gradeLevel/:level"}
            render={(props) => <NewsFreedsView {...props} />}
          />
          <Route exact component={MainContent} path="/" />
          <Route path="/welcome" component={Welcome} />
          <Route path="/product" component={ProductView} />
          <Route path="/freadom-future" component={FreadomFuture} />
          <Route path="/school-guided-path" component={SchoolGuidedPath} />
          <Route path="/school-default-guided-path" component={SchoolGuidedPath} />
        </Switch>
      </ContentContainer>
      <GlobalModal />
    </MainContainer>
  );
}

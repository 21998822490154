import React, { Component } from "react";
import {
    ThemeButton,
    ImageLoader,
    CheckBall,
    CheckBallInner,
    DropdownItem,
    FlexStart,
    SubLevelItem,
    UploadImagesParentContainer
  } from "@sc/custom.styled";
import {
    ActivityContinueButtonContainer,
    ActivityDescription,
    ActivityInputField,
    ActivityMainFormContainer,
    ActivityUploadImageButton,
    CreateActivityContainer,
    SelectFileIn,
    ImageContainer,
    ChangeImageButton,
    UploadActivityImageContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
// import { ThemeButton, ImageLoader, UploadImagesParentContainer } from "@sc/custom.styled";
import {
    ActivityCreatorNavContainer,
    ActivityDropdownLabel,
    ActivityDropdownLabelContainer,
    ActivityGradeDropdown,
    ActivityGradeDropdownContainer,
    ActivityGradeDropdownMenu,
    ActivityGradeSelectOption,
} from "@sc/ActivityModal.styled";

import { 
    FreadomPointsInputField, 
    RCHeaderDropDown, 
    RCHeaderDropDownMenu, 
    RCNameInput,
    CreateRCButton
} from "./ReadingChallengeCreationForm.styled";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as readingChallenge } from "@st/readingChallenge";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { Chevron } from "../../../static/styled/custom.styled";


class ReadingChallengeCreationForm extends Component {
    constructor(props) {
        super();
        this.state = {
            gradeSelected: null,
            gradeSelector: false,
            gradeData: [],
            selectedGrades: [],
            displayMenu: false,
            gradePackSelected: null,
            showActivityCreator: false,
            enableLoad: false,
            freadom_point: '',
            imageChange: false,
            imageBackgroundChange: false,
            enableBackgroundChange: false,

            file: "",
            readingChallenge: {
                name: "",
                description: "",
                grade: "",
                tag: [],
                image: 'https://stones2milestones.com/img/external/placeholder.png',
                background_image: 'https://stones2milestones.com/img/external/placeholder.png',
                is_published: true,
            }
        };
    }

    componentDidMount() {

        const grade = this.props.activity.active_grade || this.props.activity.grades[0];
        
        this.setState({
            gradeSelected: grade,
            readingChallenge: { ...this.state.readingChallenge, grade: grade.id }
        })

        const { activity } = this.props;

        let gradeList = activity.grades.map(grade => {
            let gradeData = {
              ...grade,
              selected: false,
              clicked: false
            };
            return gradeData;
        });

        this.setState({
            gradeData: [
              {
                name: "Select All",
                selected: false,
                clicked: false
              },
              ...gradeList
            ]
        });

    }


    handleBackgroundImageFocus = actionType => {
        if (actionType === "over" && this.state.imageBackgroundChange === false)
            this.setState({
                imageBackgroundChange: true
            });
        else if (actionType === "out" && this.state.imageBackgroundChange === true)
            this.setState({
                imageBackgroundChange: false
            });
    };

    _handleBackgroundImageChange = e => {
        // e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
            swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {
            reader.onloadend = () => {
                this.setState({
                    enableBackgroundLoad: true,
                    imageBackgroundChange: false,
                    readingChallenge: { ...this.state.readingChallenge, background_image: reader.result }
                });
            };
            reader.readAsDataURL(file);
            uploadFile(file)
                .then(res => {
                    this.setState({
                        readingChallenge: { ...this.state.readingChallenge, background_image: res.file },
                        enableBackgroundLoad: false
                    });
                })
        }
    };


    handleImageFocus = actionType => {
        if (actionType === "over" && this.state.imageChange === false)
            this.setState({
                imageChange: true
            });
        else if (actionType === "out" && this.state.imageChange === true)
            this.setState({
                imageChange: false
            });
    };

    _handleImageChange = e => {
        // e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
          swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {
        reader.onloadend = () => {
            this.setState({
                enableLoad: true,
                imageChange: false,
                readingChallenge: { ...this.state.readingChallenge, image: reader.result }
            });
        };
        reader.readAsDataURL(file);
        uploadFile(file)
            .then(res => {
                this.setState({
                    readingChallenge: { ...this.state.readingChallenge, image: res.file },
                    enableLoad: false
                });
            })
        }
    };

    displayGradeMenu = e => {
        this.setState({
            displayMenu: !this.state.displayMenu
        });
    };

    handleGradeSelector = val => {
        let newGradeData = this.state.gradeData.map(grade => {
          return { ...grade, clicked: false };
        });
        this.setState({
          gradeData: newGradeData,
          gradeSelector: val
        });
    };

    handleGradeChange = data => {
        this.handleGradeSelector(true);
        let selectedGrades = this.state.selectedGrades;
        if (data.name === "Select All" || data.name === "Unselect All") {
          // change the grade data with new grade data  
          let newGradeData = this.state.gradeData.map(grade => {
            if (grade.name === "Select All" || grade.name === "Unselect All") {
              let name =
                grade.name === "Select All" ? "Unselect All" : "Select All";
              return { ...grade, name: name, selected: !grade.selected };
            }
            return {
              ...grade,
              selected: !this.state.gradeData[0].selected,
            };
          });

          // change selected grades 
          if (data.name === "Unselect All") selectedGrades = [];
          else if(data.name === "Select All") { // storing the id's and filtering for undefined
            selectedGrades= this.state.gradeData.map(g=>g.id).filter(g=>g!==undefined)
          }
           
          this.setState({
            gradeData: newGradeData,
            selectedGrades: selectedGrades
          });
        } 
        else {    
          let newGradeData = this.state.gradeData.map(grade => {
            if (grade.name === data.name) return { ...grade, selected: !grade.selected };
            return { ...grade };
          });
          
          const data_idx=selectedGrades.indexOf(data.id);

          // removing grade if it already exists   
          (data_idx>-1)?
          (selectedGrades.splice(data_idx, 1)):
          (selectedGrades.push(data.id))

          this.setState({
            gradeData: newGradeData,
            selectedGrades: selectedGrades
          });
        }
    };

    handleGradeSelected(grades) {
        this.setState({
            displayMenu: !this.state.displayMenu,
            gradeSelected: grades,
            readingChallenge: { ...this.state.readingChallenge, grade: grades.id }
        });
    }



    handleContinueBtn = (e) => {
        this.setState({
            displayMenu: false
        });

        this.handleAddActivity();


    };

    handleAddActivity = () => {

        if (this.state.readingChallenge.name && this.state.selectedGrades.length) {
            let freadom_point= this.state.freadom_point;
            (freadom_point === '') && (freadom_point=0);

            this.state.selectedGrades.forEach(grade=> {
                this.props.readingChallenge.requestCreateReadingChallenge({ ...this.state.readingChallenge, grade: grade , freadom_point: freadom_point });
            })
            this.props.ui.hideModal();
        } else {
            swal(
                "Oops!",
                "You must enter name and grade to create reading challenge",
                "error"
            );
        }

    };

    handleChallengeName = evt => {
        this.setState({
            readingChallenge: { ...this.state.readingChallenge, name: evt.target.value }
        });
    };
    handleChallengeDescription = evt => {
        this.setState({
            readingChallenge: { ...this.state.readingChallenge, description: evt.target.value }
        });
    };

    handleFreadomPoints = evt => {
        if (
            evt.target.value.length === 0 &&
            this.state.readingChallenge.freadom_point !== ''
        ) {
            this.setState({

                freadom_point: ''

            });
        } else
            this.setState({

                freadom_point: evt.target.value

            });
    };

    render() {
        // let { imagePreviewUrl } = this.state;
        //let currentPath=window.location.pathname;
        const { activity } = this.props;

        const gradeOptions = activity.grades && activity.grades.map((grades, i) => (
            <ActivityGradeSelectOption
                onClick={this.handleGradeSelected.bind(this, grades)}
                key={i}
            >
                {grades.name}
            </ActivityGradeSelectOption>
        ));

        return (
            <CreateActivityContainer>
                <UploadImagesParentContainer>
                    {!this.state.readingChallenge.image ? (
                        <UploadActivityImageContainer height="120px" marginBottom='14px'>
                            <ActivityUploadImageButton type="button">
                                <img src={require("static/img/cloud-upload-icon.png")} alt="cloud-upload" />
                                <span>Upload Image </span>
                            </ActivityUploadImageButton>

                            <SelectFileIn
                                type="file"
                                onChange={e => this._handleImageChange(e)}
                            />
                        </UploadActivityImageContainer>
                    ) : this.state.enableLoad ? <UploadActivityImageContainer height="120px" marginBottom='14px'>
                        <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                        </ImageLoader>
                    </UploadActivityImageContainer> :
                            <UploadActivityImageContainer
                                marginBottom='14px'
                                height="120px"
                                onMouseOver={() => this.handleImageFocus("over")}
                                onMouseOut={() => this.handleImageFocus("out")}
                            >
                                <ImageContainer src={this.state.readingChallenge.image} />
                                <ChangeImageButton
                                    top={this.state.imageChange ? "83%" : "100%"}
                                    opacity={this.state.imageChange ? 1 : 0}
                                >
                                    Change Image
                        </ChangeImageButton>
                                <SelectFileIn
                                    type="file"
                                    onChange={e => this._handleImageChange(e)}
                                />
                            </UploadActivityImageContainer>
                    }

                    {!this.state.readingChallenge.background_image ? (
                        <UploadActivityImageContainer height="190px" >
                            <ActivityUploadImageButton type="button">
                                <div> <img src={require("static/img/cloud-upload-icon.png")} alt="cloud-upload" />
                                </div>
                                <span> Upload Full screen Image </span>
                            </ActivityUploadImageButton>

                            <SelectFileIn
                                type="file"
                                onChange={e => this._handleBackgroundImageChange(e)}
                            />

                        </UploadActivityImageContainer>
                    ) : this.state.enableBackgroundLoad ? <UploadActivityImageContainer height="190px">
                        <ImageLoader> <img alt="loading" width="30" height="30"
                            src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                        </ImageLoader>
                    </UploadActivityImageContainer> :
                            <UploadActivityImageContainer
                                height="190px"
                                onMouseOver={() => this.handleBackgroundImageFocus("over")}
                                onMouseOut={() => this.handleBackgroundImageFocus("out")}
                            >
                                <ImageContainer src={this.state.readingChallenge.background_image} />
                                <ChangeImageButton
                                    top={this.state.imageBackgroundChange ? "83%" : "100%"}
                                    opacity={this.state.imageBackgroundChange ? 1 : 0}
                                >
                                    Change Image
                        </ChangeImageButton>
                                <SelectFileIn
                                    type="file"
                                    onChange={e => this._handleBackgroundImageChange(e)}
                                />
                            </UploadActivityImageContainer>
                    }
                </UploadImagesParentContainer>

                <ActivityMainFormContainer>
                    <form>
                        <ActivityCreatorNavContainer>

                            <RCHeaderDropDown
                                active={this.state.gradeSelector}
                                onBlur={() => this.handleGradeSelector(false)}
                                tabIndex="1"
                                >
                                <div
                                    style={{ fontSize: "1rem" }}
                                    onClick={() =>this.handleGradeSelector(!this.state.gradeSelector)}
                                >
                                    {this.state.selectedGrades.length >= 7
                                    ? "All "
                                    : this.state.selectedGrades.length + " "}{" "}
                                    Grades Selected
                                    <Chevron>
                                    <img
                                        src={require("static/img/downarrowicon.png")}
                                        alt="down-arrow"
                                    />
                                    </Chevron>
                                </div>
                                <RCHeaderDropDownMenu
                                    maxHeight="150px"
                                    active={this.state.gradeSelector}
                                    color="#fff"
                                    style={{ padding: "0px" }}
                                >
                                    {this.state.gradeData.map((gradeData, index) => (
                                    <DropdownItem key={index}>
                                        <FlexStart
                                        style={{
                                            width: "100%",
                                            padding: "6px 8px",
                                            position: "relative",
                                            borderBottom: "solid 1px #e6e6e6"
                                        }}
                                        onClick={() => this.handleGradeChange(gradeData)}
                                        >
                                        <CheckBall
                                            style={{ marginRight: "6px" }}
                                            active={gradeData.selected || gradeData.clicked}
                                        >
                                            <CheckBallInner
                                            active={gradeData.selected || gradeData.clicked}
                                            />
                                        </CheckBall>
                                        {gradeData.name}
                                        </FlexStart>
                                    </DropdownItem>
                                    ))}
                                </RCHeaderDropDownMenu>
                                </RCHeaderDropDown>

                            <FreadomPointsInputField>
                            <input
                                type="number"
                                placeholder="Freadom Points"
                                className="form-control"
                                value={this.state.freadom_point || ''}
                                onChange={this.handleFreadomPoints}
                            />
                            </FreadomPointsInputField>
                        </ActivityCreatorNavContainer>

                        <RCNameInput
                            type="text"
                            className="form-control"
                            autoFocus
                            placeholder="Add Reading Challenge Title"
                            value={this.state.readingChallenge.name || ''}
                            onChange={this.handleChallengeName}
                        />
                        

                        <div>
                            <textarea
                                className="form-control"
                                placeholder="Add a short description"
                                style={{ height: "200px" }}
                                value={this.state.readingChallenge.description || ''}
                                onChange={this.handleChallengeDescription}
                            />
                        </div>
                        <ActivityContinueButtonContainer>
                            <CreateRCButton
                                primary
                                className="btn btn-warning"
                                type="button"
                                onClick={() => this.handleContinueBtn()}
                            >
                                Create Reading Challenge
                            </CreateRCButton>
                        </ActivityContinueButtonContainer>
                    </form>
                </ActivityMainFormContainer>
            </CreateActivityContainer>
        );
    }
}
// export default ReadingChallengeCreationForm;
export default connectStore({
    ui,
    activity, readingChallenge
})(ReadingChallengeCreationForm)
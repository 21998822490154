import React, { Component } from "react";
import {
  AlignRow,
  EditIcon,
  ParentBodyContentContainer,
  ParentDetailsLabel,
  ParentFormField,
  SaveButton,
} from "components/users-view/parent-profile/ParentProfile.styled";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

// export default connectStore({
//   user,
//   ui,
// })

class ParentProfileInfo extends Component {
  constructor() {
    super();
    this.state = {
      enableEdit: false,
      parent_info: {
        id: "",
        name: "",
        contact_no: "",
        email: "",
        password: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.active_parent) {
      this.normalizeForState(nextProps.user.active_parent);
    } else {
      this.setState({
        parent_info: {
          ...this.state.parent_info,
          id: "",
          name: "",
          contact_no: "",
          email: "",
          password: "",
        },
      });
    }
  }

  normalizeForState = (parentInfo) => {
    this.setState({
      parent_info: {
        ...this.state.parent_info,

        name: parentInfo.name,
        id: parentInfo.id,
        contact_no: parentInfo.contact_no,
        email: parentInfo.email,
        password: "",
      },
    });
  };

  enableEdit = () => {
    this.setState({
      enableEdit: !this.state.enableEdit,
    });
    // console.log("edit", this.state.enableEdit);
  };

  handleNameChange = (evt) => {
    this.setState({
      parent_info: { ...this.state.parent_info, name: evt.target.value },
    });
  };
  handlePhoneNumberChange = (evt) => {
    this.setState({
      parent_info: { ...this.state.parent_info, contact_no: evt.target.value },
    });
  };
  handleEmailChange = (evt) => {
    this.setState({
      parent_info: { ...this.state.parent_info, email: evt.target.value },
    });
  };
  handlePasswordChange = (evt) => {
    this.setState({
      parent_info: { ...this.state.parent_info, password: evt.target.value },
    });
  };

  saveChangesBtn = () => {
    this.enableEdit();
    if (this.state.parent_info.password.length !== 0)
      this.props.user.requestUpdateParentProfile(this.state.parent_info);
    else {
      let data = {
        id: this.state.parent_info.id,
        email: this.state.parent_info.email,
        name: this.state.parent_info.name,
        contact_no: this.state.parent_info.contact_no,
      };
      this.props.user.requestUpdateParentProfile(data);
    }
  };

  openKidsList = () => {
    this.props.ui.showModal("KIDSLIST");
  };

  render() {
    let state = this.state;
    const {permission} = this.props.onboard;
    const {user} = this.props.onboard;
    return (
      <ParentBodyContentContainer>
        {this.props.user && this.props.user.active_parent ? (
          <div>
            <AlignRow>
              <ParentDetailsLabel> User Details </ParentDetailsLabel>
              {!state.enableEdit && Permission.getPermission(permission, "change_user") ? (
                <EditIcon onClick={this.enableEdit}>
                  <div>
                    <img
                      src={require("static/img/editicon.png")}
                      alt="edit-icon"
                    />
                  </div>
                </EditIcon>
              ) : null}
            </AlignRow>

            <AlignRow>
              <ParentFormField className="col-sm-8 col-md-4 col-lg-4">
                <input
                  type="text"
                  autoFocus
                  placeholder="Full Name"
                  value={state.parent_info.name}
                  onChange={this.handleNameChange}
                  disabled={!state.enableEdit}
                />
              </ParentFormField>
              <div className="col-sm-4 col-md-2 col-lg-1"></div>
              <ParentFormField className="col-sm-8 col-md-4 col-lg-4">
                <input
                  type="email"
                  placeholder="Email ID"
                  autoFocus
                  value={state.parent_info.email}
                  onChange={this.handleEmailChange}
                  disabled={!state.enableEdit}
                />
              </ParentFormField>

              {/*<ParentFormField className="col-sm-8 col-md-3 col-lg-3">*/}
              {/*<input type="text" placeholder="Last name"/>*/}
              {/*</ParentFormField>*/}
            </AlignRow>

            {!user.read_only ?
            <AlignRow>
              
                <ParentFormField className="col-sm-8 col-md-3 col-lg-4">
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    autoFocus
                    value={state.parent_info.contact_no}
                    onChange={this.handlePhoneNumberChange}
                    disabled={!state.enableEdit}
                  />
                </ParentFormField>
              <div className="col-sm-4 col-md-2 col-lg-1"></div>
              <ParentFormField className="col-sm-8 col-md-3 col-lg-4">
                <input
                  type="password"
                  placeholder="Change Password"
                  autoFocus
                  value={state.parent_info.password}
                  onChange={this.handlePasswordChange}
                  disabled={!state.enableEdit}
                />
              </ParentFormField>
            </AlignRow>:null}

            <SaveButton primary onClick={this.openKidsList}>
              {" "}
              View Kids List{" "}
            </SaveButton>

            {state.enableEdit ? (
              <SaveButton primary left="20px" onClick={this.saveChangesBtn}>
                {" "}
                Save Changes{" "}
              </SaveButton>
            ) : null}
          </div>
        ) : null}
      </ParentBodyContentContainer>
    );
  }
}

// export default ParentProfileInfo;
export default connectStore({
  user,
  ui,
  onboard
 })(ParentProfileInfo)
import React, { Component } from "react";
import styled from "styled-components";
import KidsList from "@c/users-view/parent-profile/kids-list";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import actions from "@st/ui/actions";
import store from "store";
import stars from "@i/stars.png";
import ChildPaymentPopUp from "@c/users-view/child-profile/child-payment-pop-up/ChildPaymentPopUp";
import ActivityCreationForm from "@c/activity-view/activity-creation-form/ActivityCreationForm";
import TagCreationForm from "@c/tag-view/tag-creation-form";
import ActivityPackCreationForm from "@c/activity-view/activity-pack-creation-form/ActivityPackCreationForm";
import CaseStudyCreatorForm from "@c/case-study-view/case-study-creator-form/CaseStudyCreatorForm";
import CreateAssessmentForm from "@c/assessments-view/create-assessment-form/CreateAssessmentForm";
import ReadingChallengeCreationForm from "@c/reading-challenge-view/reading-challenge-creation-form/ReadingChallengeCreationForm";
import StoryCreationForm from "@c/story-view/story-creation-form/StoryCreationForm";
import StoryCollectionCreator from "@c/story-view/story-collection-creator/StoryCollectionCreator";
import PlanPopUp from "@c/common/header/plan-pop-up/PlanPopUp";
import TagSelector from "@c/common/tag-selector";
import ReactDOM from "react-dom";
import ExportExcelModal from "@c/users-view/export-excel-modal/export-excel-modal";
import SchoolView from "../flive-code-view/school-view";
import SchoolCreatorForm from "../school-code-view/school-creator-form/SchoolCreatorForm";
import ClassCreatorForm from "../school-code-view/add-class-view/ClassCreatorForm";
import QuizCreationForm from "../quiz2-view/quiz-creation-form/QuizCreationForm";
import FutureFreadomCreationForm from "@c/freadom-future-view/freadom-future-creation-form/FutureFreadomCreationForm";
import SchoolGuidedCreationForm from "@c/school-guided-path-view/school-guided-path-creation-form/SchoolGuidedPathCreationForm";
import ProductEditForm from "@c/product-view/product-edit-form";

export const id = {
  // ACTIVITY: 'ACTIVITY',
  KIDSLIST: "KIDSLIST",
  ACTIVITYFORM: "ACTIVITYFORM",
  TAGFORM: "TAGFORM",
  ACTIVITYPACKFORM: "ACTIVITYPACKFORM",
  CASESTUDYFORM: "CASESTUDYFORM",
  ASSESSMENTSFORM: "ASSESSMENTSFORM",
  READINGCHALLENGEFORM: "READINGCHALLENGEFORM",
  QUIZFORM: "QUIZFORM",
  STORYFORM: "STORYFORM",
  COLLECTIONFORM: "COLLECTIONFORM",
  PLAN: "PLAN",
  PAYMENTS_LIST: "PAYMENTS_LIST",
  TAGSELECTORFORM: "TAGSELECTORFORM",
  SCHOOLEDIT: "SCHOOLEDIT",
  ADDSCHOOLFORM: "ADDSCHOOLFORM",
  ADDCLASSFORM: "ADDCLASSFORM",
  FREADOMFUTURE: "FREADOMFUTURE",
  SCHOOLGUIDEDPATH: "SCHOOLGUIDEDPATH",
  PRODUCTUPDATEFORM: "PRODUCTUPDATEFORM",
  productCreateForm: "productCreateForm",
};
// export default connectStore({
//   ui
// })

class GlobalModal extends Component {
  componentDidMount() {
    // Handle ESC key (key code 27)
    document.addEventListener("keyup", function(e) {
      if (e.keyCode === 27) {
        store.dispatch(actions.hideModal());
      }
    });
  }

  disableModalClick = (evt) => {
    const area = ReactDOM.findDOMNode(this.refs.modalBox);
    if (!area.contains(evt.target) && this.props.ui.show_modal) {
      this.props.ui.hideModal();
    }
  };

  render() {
    let comp_id = this.props.ui.show_modal;
    return (
      <div>
        <ModalBackDrop active={comp_id} />
        <DoubleModalBackDrop active={comp_id === id.TAGSELECTORFORM} />
        <ModalContainer
          active={comp_id}
          id="modalContainer"
          onClick={this.disableModalClick}
        >
          <ModalView id="modalDisplay" ref="modalBox">
            <CloseIconBar onClick={this.props.ui.hideModal}>
              <img
                src={require("static/img/white-cross.png")}
                alt="close icon"
              />
            </CloseIconBar>
            <ModalViewContainer planFlow={comp_id === id.PLAN}>
              {comp_id === id.KIDSLIST && <KidsList />}
              {comp_id === id.ACTIVITYFORM && <ActivityCreationForm />}
              {comp_id === id.TAGFORM && <TagCreationForm />}
              {comp_id === id.TAGSELECTORFORM && <TagSelector />}
              {comp_id === id.CASESTUDYFORM && <CaseStudyCreatorForm />}
              {comp_id === id.ACTIVITYPACKFORM && <ActivityPackCreationForm />}
              {comp_id === id.ASSESSMENTSFORM && <CreateAssessmentForm />}
              {comp_id === id.ADDSCHOOLFORM && <SchoolCreatorForm />}
              {comp_id === id.ADDCLASSFORM && <ClassCreatorForm />}
              {comp_id === id.READINGCHALLENGEFORM && (
                <ReadingChallengeCreationForm />
              )}
              {comp_id === id.QUIZFORM && <QuizCreationForm />}
              {comp_id === id.STORYFORM && <StoryCreationForm />}
              {comp_id === id.COLLECTIONFORM && <StoryCollectionCreator />}
              {comp_id === id.PLAN && <PlanPopUp />}
              {comp_id === id.PAYMENTS_LIST && <ChildPaymentPopUp />}
              {comp_id === "EXPORTEXCELMODAL" && <ExportExcelModal />}
              {comp_id === id.SCHOOLEDIT && <SchoolView></SchoolView>}
              {comp_id === id.FREADOMFUTURE && <FutureFreadomCreationForm />}
              {comp_id === id.SCHOOLGUIDEDPATH && <SchoolGuidedCreationForm />}
              {comp_id === id.PRODUCTUPDATEFORM && <ProductEditForm />}
              {comp_id === id.productCreateForm && (
                <ProductEditForm isCreateForm />
              )}
            </ModalViewContainer>
          </ModalView>
        </ModalContainer>
      </div>
    );
  }
}

export default connectStore({
  ui,
})(GlobalModal);

export const ModalBackDrop = styled.div`
  position: fixed;
  background-color: var(--primary-color);
  background-image: url(${stars});
  top: 0;
  transition: all 0.5s;
  opacity: ${(props) => (props.active ? 0.8 : 0)};
  pointer-events: ${(props) => (props.active ? "unset" : "none")};
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  transition: 0.3s;
  background-size: cover;
  max-width: 100%;
`;

export const EXPORTEXCELMODAL = styled.div`
  height: 200px;
`;

export const DoubleModalBackDrop = styled.div`
  position: fixed;
  background-color: var(--primary-color);
  background-image: url(${stars});
  top: 0;
  transition: all 0.5s;
  opacity: ${(props) => (props.active ? 0.8 : 0)};
  pointer-events: ${(props) => (props.active ? "unset" : "none")};
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: 0.3s;
  background-size: cover;
  max-width: 100%;
`;

export const ModalView = styled.div`
  position: relative;
  min-height: 100px;
  min-width: 35%;
  transition: 0.3s;
  max-width: 700px;
  background: white;
  border-radius: 10px;
`;

export const ModalViewContainer = styled.div`
  max-height: 700px;
  overflow-y: ${(props) => (props.planFlow ? "inherit" : "auto")};
  overflow-x: ${(props) => (props.planFlow ? "inherit" : "hidden")};
  border-radius: 10px;
`;

export const CloseIconBar = styled.span`
  padding: 10px;
  position: absolute;
  right: -70px;
  top: -10px;
  cursor: pointer;
  > img {
    height: 20px;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
  transform: ${(props) =>
    props.active ? "translateY(0)" : "translateY(200px)"};
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? "unset" : "none")};
  transition: all 0.2s;
`;

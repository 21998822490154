import React, {Component} from 'react';
import {connectStore} from "redux-box";
import {module as newsFreeds} from 'store/newsFreeds'
import {NavigationContainer, NavigationHeaderContainer} from "static/styled/NavigationBar.styled";
import InfiniteScroll from 'react-infinite-scroller';
import NewsFreedsCard from "./news-freeds-card/NewsFreedsCard";
import {
    AlignContentHorizontal, EmptyContainer,AutoContainer, LoadingContainer,
    InfiniteScrollParentContainer
} from "static/styled/custom.styled";
import {Link} from "react-router-dom";
import {InfiniteScrollLoader} from "../../../static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


class NewsFreedsList extends Component {

    handleSubLevelSelector = (data) => {
        let {newsFreeds} = this.props;
        newsFreeds.setActiveNewsLevel(data);
        newsFreeds.requestNewsFreeds();


    };

    fetchMoreNews = () =>{
        let {newsFreeds}=this.props;
        if(newsFreeds.news_freeds_result!==null && newsFreeds.news_freeds_result.has_next)
        { //// console.log("hello");
            setTimeout(() => {
                if(this.props.newsFreeds.news_freeds_result!==null && this.props.newsFreeds.news_freeds_result.next_page>0)
                    this.props.newsFreeds.requestNewsFreedsByOffset(this.props.newsFreeds.news_freeds_result.next_page);

            }, 300);}
    }
    ;

    render() {
        const { permission } = this.props.onboard;

        const data = ['Easy', 'Hard'];
        let {newsFreeds} = this.props;
        let subLevelList = data.map((level, i) => (
            <Link to={"/news-freads/" + newsFreeds.active_news_grade_level + "/" + level}
                  key={i}
                  className={level === newsFreeds.active_news_level ? "active" : ""}
                  onClick={() => this.handleSubLevelSelector(level)}>
                {level}
            </Link>
        ));

        let newsList =(<AlignContentHorizontal marginBottom="70px">
                { this.props.newsFreeds.news_freeds_list && this.props.newsFreeds.news_freeds_list.length>0 &&
                this.props.newsFreeds.news_freeds_list.map((data,index)=>
                    <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                        {/* { !Permission.getPermission(permission, "read_only")? */}
                            <Link
                            to={"/news-freads/"+newsFreeds.active_news_grade_level+"/" + newsFreeds.active_news_level+"/edit/"+data.id}>
                            <NewsFreedsCard newsInfo={data}/>
                        </Link>
                        {/* :<NewsFreedsCard newsInfo={data}/>
                        } */}
                    </div>)}
            </AlignContentHorizontal>
        );

        return (
            <AutoContainer>
                <NavigationHeaderContainer shadow>
                    <NavigationContainer>
                        {subLevelList}
                    </NavigationContainer>
                </NavigationHeaderContainer>
                {this.props.newsFreeds.fetch_news_freeds_list ? <LoadingContainer style={{zIndex: '100'}}>
                        <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")}/>
                    </LoadingContainer> :
                   (this.props.newsFreeds.news_freeds_list && this.props.newsFreeds.news_freeds_list.length>0 ?
                        <InfiniteScrollParentContainer>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.fetchMoreNews}
                                hasMore={this.props.newsFreeds.news_freeds_result && this.props.newsFreeds.news_freeds_result.has_next}
                                style={{position:'relative'}}
                                loader={<InfiniteScrollLoader className="loader" key={0} > <img alt="loading" width="50" height="50" src={require("static/img/Theme-loader.gif")}/> </InfiniteScrollLoader>}
                                useWindow={false}

                            >
                                {newsList}
                            </InfiniteScroll></InfiniteScrollParentContainer>:  <EmptyContainer>
                            <h4> NO NEWS FREADS ADDED FOR THIS LEVEL</h4>
                        </EmptyContainer>)}
            </AutoContainer>)

    }
}
export default connectStore({
    newsFreeds,onboard
})(NewsFreedsList)
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  UserDetailsContainer,
  UserDetailValueLabels,
  UserField2,
  UserFieldEmail,
  UserFieldName,
  UserListLoadingContainer,
  Pagination,
  PaginationButton
} from "../../MainContent.styled";
import {
  AutoContainer,
  ListContainer,
  EmptyContainer
} from "@sc/custom.styled";
import ChildCard from "./child-card/ChildCard";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
// export default connectStore({
//   user
// })

class ChildrenList extends Component {
  constructor(props) {
    super();
  }
  componentWillMount() {
    this.props.user.requestChildren();
  }

  componentWillUnmount() {
    this.props.user.setActiveChild(null);
    this.props.user.unsetChildrenList();
  }
  handleNext = () => {
    let { user } = this.props;
    if (user.children_list.has_next) {
      user.requestChildrenByOffset(user.children_list.next_page);
    }
  };
  handlePrevious = () => {
    let { user } = this.props;
    if (user.children_list.prev_page >= 0 && user.children_list.offset !== 0) {
      user.requestChildrenByOffset(user.children_list.prev_page);
    }
  };

  render() {
    const { user } = this.props.onboard;

    let children =
      this.props.user &&
        this.props.user.children_list.result &&
        this.props.user.children_list.result.length > 0 ? (
        this.props.user.children_list.result.map((child, index) =>
          (
            <Link to={"/users/children/" + child.id} key={index}>
              <ChildCard child={child} />
            </Link>
          )
        )
      ) : (
        <EmptyContainer>
          <h4>NO CHILDREN RECORDS ADDED</h4>
        </EmptyContainer>
      );
    return (
      <UserDetailsContainer>
        <AutoContainer style={{ padding: "0 0 50px 0" }}>
          <UserDetailValueLabels>
            <UserFieldName primary>Name</UserFieldName>
            <UserFieldName primary>School Name</UserFieldName>
            <UserField2 primary> Grade</UserField2>
            <UserFieldName primary>Parent Name</UserFieldName>
            <UserFieldEmail primary width="160px">
              Invite code
            </UserFieldEmail>
            {/* <UserField2 primary>Phone no.</UserField2> */}

            { !user.read_only ? <UserFieldEmail primary align width="240px">
              Status
            </UserFieldEmail>:null}
          </UserDetailValueLabels>
          {this.props.user && this.props.user.fetch_child ? (
            <UserListLoadingContainer>
              <img
                alt="loading"
                width="40"
                height="40"
                src={require("static/img/Theme-loader.gif")}
              />
            </UserListLoadingContainer>
          ) : (
            <ListContainer style={{ padding: "0 5px" }}>
              {children}
            </ListContainer>
          )}
        </AutoContainer>

        <Pagination>
          <PaginationButton
            color={
              this.props.user.children_offset !== 0 ? "#ff8a23" : "#d5d5d5"
            }
            onClick={this.handlePrevious}
          >
            <i className="fa fa-chevron-left" />
          </PaginationButton>
          <PaginationButton
            color={
              this.props.user.children_list &&
                this.props.user.children_list.has_next
                ? "#ff8a23"
                : "#d5d5d5"
            }
            onClick={this.handleNext}
          >
            <i className="fa fa-chevron-right" />
          </PaginationButton>
        </Pagination>
      </UserDetailsContainer>
    );
  }
}
// export default ChildrenList;
export default connectStore({
  user, onboard
})(ChildrenList)

import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { Link } from "react-router-dom";
import SchoolGuidedPathCard from "./school-guided-path-card/SchoolGuidedPathCard";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import { module as onboard } from "@st/onboard";
import {
  SectionDropDown,
  SelectTag,
  OptionTag,
} from "./SchoolGuidedPathList.styled";

class SchoolGuidedPathList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classList: [],
      searchBarEnable: false,
      isComponentRendered: false,
      activeGrade: null,
      units: [],
      sectionList: [],
      selectedSection: null,
      refreshComponent: false,
      grades: [],
      gradeChanged: false,
      id: "",
    };
  }
  componentDidMount() {
    this.setState({ isComponentRendered: true });
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");

    this.setState({ id });
    if (
      this.props.schoolGuidedPath.active_grade &&
      typeof this.props.schoolGuidedPath.active_grade == "string"
    ) {
      this.props.schoolGuidedPath.requestByGradeId({
        grade: this.props.schoolGuidedPath.active_grade,
        guidedPath: id,
      });
    }
  }

  fetchMoreReadingChallenges = () => {
    if (
      this.props.schoolGuidedPath.result !== null &&
      this.props.schoolGuidedPath.result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.schoolGuidedPath.result !== null &&
          this.props.schoolGuidedPath.result.next_page > 0
        )
          this.props.schoolGuidedPath.requestConcat(
            this.props.schoolGuidedPath.result.next_page
          );
      }, 100);
    }
  };

  handleGradeChange = (grade) => {
    // get all a tags with class active
    let activeElements = document.getElementsByClassName("active");
    if (activeElements) {
      // remove active class from all a tags
      for (let i = 0; i < activeElements.length; i++) {
        activeElements[i].classList.remove("active");
      }
    }
    console.log(this.state.id);
    this.props.schoolGuidedPath.requestByGradeId({
      grade: grade.id,
      guidedPath: this.state.id,
    });
  };

  filterReadingChallenge = () => {
    return this.props.schoolGuidedPath.List &&
      this.props.schoolGuidedPath.active_grade
      ? this.props.schoolGuidedPath.List.filter((schoolGuidedPath) => {
          return (
            schoolGuidedPath.grade ===
              this.props.schoolGuidedPath.active_grade &&
            this.props.schoolGuidedPath.active_grade
          );
        })
      : [];
  };

  handleReadingSearch = (evt) => {
    let { schoolGuidedPath } = this.props;
    schoolGuidedPath.setSearch(evt.target.value);
    setTimeout(() => {
      this.props.schoolGuidedPath.requestSchoolGuidedPath();
    }, 2000);
  };

  handleClearSearch = () => {
    let { schoolGuidedPath } = this.props;
    schoolGuidedPath.setSearch("");
    schoolGuidedPath.requestSchoolGuidedPath();
  };

  enableSearchBar = (actionType) => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true,
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false,
      });
  };

  componentDidUpdate(prevProps) {
    const { active_grade, List } = this.props.schoolGuidedPath;

    if (List !== prevProps.schoolGuidedPath.List) {
      this.setState({ units: List });
    }
    if (active_grade !== prevProps.schoolGuidedPath.active_grade) {
      this.setState({ activeGrade: active_grade });
    }
  }

  render() {
    const { location } = this.props;
    const { grades } = this.state;
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            this.props.schoolGuidedPath.active_grade &&
            grade.id === this.props.schoolGuidedPath.active_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span id={grade.id}> {grade.name}</span>
        </a>
      ));

    let editRoute = location.pathname.includes("/school-guided-path")
      ? "/school-guided-path/edit/"
      : "/school-default-guided-path/edit/";

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        {this.state.units && this.state.units.length > 0 ? (
          this.state.units.map((schoolGuidedPathInfo, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                <Link to={editRoute + schoolGuidedPathInfo.id}>
                  <SchoolGuidedPathCard
                    schoolGuidedPathInfo={schoolGuidedPathInfo}
                  />
                </Link>
              </div>
            );
          })
        ) : (
          <EmptyContainer>
            <h4>NO UNIT FOR THIS SECTION</h4>
          </EmptyContainer>
        )}
      </AlignContentHorizontal>
    );
    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer className="grades_list">
            {classList}
          </NavigationContainer>

          {/* <SectionDropDown>

              <div className="input-group mx-2 my-1" style={{width: 'fit-content',border: '1px solid black'}}>
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">Section</label>
                </div>
                  <SelectTag
                    className="SectionDropdown"
                    onChange={this.handleSections}
                  >

                    {Object.entries(this.state.sectionList).map(([key, value]) => (
                      <OptionTag key={value.id} id={value.grade} value={value.id}>
                        {value.name}
                      </OptionTag>
                    ))}
                  </SelectTag>

              </div>
            </SectionDropDown> */}

          {/* <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.schoolGuidedPath.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.schoolGuidedPath.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer> */}
        </NavigationHeaderContainer>
        <div className="units">
          <button id="hiddenButton" hidden></button>
          <InfiniteScrollParentContainer noTop>
            <InfiniteScroll
              style={{ position: "relative" }}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {readingChallengeList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        </div>
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  schoolGuidedPath,
  onboard,
})(SchoolGuidedPathList);

import React, { Component } from "react";
import {
  FlexBetween,
  SubHeading,
  UploadContainer,
  ThemeButton,
} from "@sc/custom.styled";
export class QuizLinks extends Component {
  copyLink = (input) => {
    setTimeout(() => {
      let copyText = document.getElementById(input);
      //console.log('copyText copyText', copyText)
      copyText.focus();
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      //console.log('copy success', document.execCommand('copy'));
      copyText.blur();
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }, 200);
  };
  render() {
    return (
      <UploadContainer>
        <FlexBetween>
          <SubHeading primary>Links</SubHeading>
        </FlexBetween>
        <FlexBetween>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "15%" }}>
                <p style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                  Web Link
                </p>
              </td>
              <td>
                <input
                  type="text"
                  id="web_link"
                  style={{
                    width: "100%",
                    opacity: 0.7,
                    borderRadius: "5px",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                  readOnly
                  value={"http://rotaryeducation.getfreadom.com/quiz/" + this.props.id}
                />
              </td>
              <td style={{ paddingLeft: "1rem", width: "10%" }}>
                <ThemeButton
                  style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                  onClick={() => this.copyLink("web_link")}
                >
                  COPY
                </ThemeButton>
              </td>
            </tr>
            <tr>
              <td style={{ width: "15%" }}>
                <p style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                  App Link
                </p>
              </td>
              <td>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    opacity: 0.7,
                    borderRadius: "5px",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                  readOnly
                  id="app_link"
                  //FREAD-1240
                  //value={"https://app.getfreadom.com/"+this.props.id}
                  value={"https://al8yp.app.link/"+this.props.applink.split('/').pop()}
                  // value=""
                />
              </td>
              <td style={{ paddingLeft: "1rem", width: "10%" }}>
                <ThemeButton
                  style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                  onClick={() => this.copyLink("app_link")}
                >
                  COPY
                </ThemeButton>
              </td>
            </tr>
          </table>
        </FlexBetween>
      </UploadContainer>
    );
  }
}

export default QuizLinks;

import React, { Component } from "react";
import {
  ThemeButton,
  ImageLoader,
  DropDown,
  DropDownMenu,
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  SubLevelItem,
} from "@sc/custom.styled";
import {
  ActivityUploadImageButton,
  ChangeImageButton,
  CreateActivityContainer,
  ImageContainer,
  SelectFileIn,
  ActivityDescription,
  ActivityInputField,
  UploadActivityImageContainer,
} from "../../activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  CheckBox,
  PublishContainer
} from "static/styled/custom.styled";
import { module as ui } from "@st/ui";
import { module as story } from "@st/story";
import { module as level } from "@st/level";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import {
  StoryCreationMainForm,
  StoryImagesContainer,
  StoryInputContainer,
  StoryInputField,
} from "./StoryCreationForm.styled";
import swal from "sweetalert2";
import { Chevron } from "../../../static/styled/custom.styled";

const errorHandler = (err) => {
  swal({
    text: err,
    type: "warning",
  });
};


class StoryCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      levelSelector: false,
      readingSelector: false,
      storyTypeSelector: false,
      fileTypeSelector: false,
      imageChange: false,
      levelData: [],
      selectedLevels: [],
      imageBackgroundChange: false,
      enableBackgroundLoad: false,
      enableLoad: false,
      story_info: {
        image: "",
        reading_time: "",
        author_name: "",
        name: "",
        description: "",
        story_type: "",
        source: "",
        background_image: "",
        landscape:false,
      },
    };
  }

  componentDidMount() {
    let levelList = this.props.level.levels.map((level) => {
      let levelData = {
        name: level,
        selected: false,
        clicked: false,
        levels: [
          { name: "Easy", selected: false },
          { name: "Hard", selected: false },
        ],
      };
      return levelData;
    });
    this.setState({
      levelData: [
        {
          name: "Select All",
          selected: false,
          clicked: false,
          levels: [],
        },
        ...levelList,
      ],
    });
  }

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          story_info: { ...this.state.story_info, image: reader.result },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          story_info: { ...this.state.story_info, image: res.file },
          enableLoad: false,
        });
      });
    }
  };

  handleBackgroundImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageBackgroundChange === false)
      this.setState({
        imageBackgroundChange: true,
      });
    else if (actionType === "out" && this.state.imageBackgroundChange === true)
      this.setState({
        imageBackgroundChange: false,
      });
  };

  _handleBackgroundImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageBackgroundChange: false,
          enableBackgroundLoad: true,
          story_info: {
            ...this.state.story_info,
            background_image: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          story_info: { ...this.state.story_info, background_image: res.file },
          enableBackgroundLoad: false,
        });
      });
    }
  };

  handleReadingTime = (data) => {
    this.handleReadingSelector(false);
    this.setState({
      story_info: {
        ...this.state.story_info,
        reading_time: data + " mins",
      },
    });
  };

  handleLevelChange = (data) => {
    this.handleLevelSelector(true);
    let storyLevels = [];
    if (data === "Select All" || data === "Unselect All") {
      let newLevelData = this.state.levelData.map((level) => {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map((subLevel) => {
            storyLevels = storyLevels.concat(level.name + ":" + subLevel.name);

            return { ...subLevel, selected: !this.state.levelData[0].selected };
          });
        if (level.name === "Select All" || level.name === "Unselect All") {
          let name =
            level.name === "Select All" ? "Unselect All" : "Select All";
          return { ...level, name: name, selected: !level.selected };
        }
        return {
          ...level,
          selected: !this.state.levelData[0].selected,
          levels: subLevels,
        };
      });
      if (data === "Unselect All") storyLevels = [];
      this.setState({
        levelData: newLevelData,
        selectedLevels: storyLevels,
      });
    } else {
      let newLevelData = this.state.levelData.map((level) => {
        if (level.name === data) return { ...level, clicked: !level.clicked };
        return { ...level, clicked: false };
      });
      this.setState({
        levelData: newLevelData,
        story_info: {
          ...this.state.story_info,
          level: data,
        },
      });
    }
  };

  handleSubLevelSelector = (levelName, subLevel) => {
    let newLevelData = this.state.levelData.map((level) => {
      if (level.name === levelName) {
        let val = false;
        let subLevels = level.levels.map((subLevelOption) => {
          if (subLevelOption.name === subLevel.name) {
            //console.log(this.state.selectedLevels);
            if (subLevel.selected) {
              let storyLevels = this.state.selectedLevels.filter(
                (selectedLevel) => {
                  return selectedLevel !== levelName + ":" + subLevel.name;
                }
              );

              this.setState({
                selectedLevels: storyLevels,
              });
            } else {
              this.setState({
                selectedLevels: this.state.selectedLevels.concat(
                  levelName + ":" + subLevel.name
                ),
              });
            }

            return { ...subLevelOption, selected: !subLevelOption.selected };
          }
          //else if(subLevelOption.selected)
          return subLevelOption;
        });
        val = subLevels && (subLevels[0].selected || subLevels[1].selected);
        return { ...level, selected: val, levels: subLevels };
      }
      return { ...level };
    });
    this.setState({
      levelData: newLevelData,
    });

    //console.log(this.state.selectedLevels);
  };

  handleSpecLevelSelector = (evt, selectedLevel) => {
    evt.preventDefault();
    evt.stopPropagation();
    let storyLevels = this.state.selectedLevels;
    let newLevelData = this.state.levelData.map((level) => {
      if (level.name === selectedLevel.name) {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map((subLevel) => {
            if (level.selected) {
              if (
                storyLevels &&
                storyLevels.some(
                  (data) => data === level.name + ":" + subLevel.name
                )
              )
                storyLevels = storyLevels.filter((selectedLevel) => {
                  return selectedLevel !== level.name + ":" + subLevel.name;
                });
            } else {
              if (
                !this.state.selectedLevels.some(
                  (data) => data === level.name + ":" + subLevel.name
                )
              ) {
                storyLevels = storyLevels.concat(
                  level.name + ":" + subLevel.name
                );
              }
            }
            return { ...subLevel, selected: !level.selected };
          });

        return { ...level, selected: !level.selected, levels: subLevels };
      }

      return level;
    });

    this.setState({
      levelData: newLevelData,
      selectedLevels: storyLevels,
    });
  };

  handleStoryType = (data) => {
    console.log(this.state.selectedLevels);
    this.handleStorySelector(false);
    this.setState({
      story_info: {
        ...this.state.story_info,
        story_type: data,
      },
    });
  };

  handleFileType = (data) => {
    this.handleFileSelector(false);
    this.setState({
      story_info: {
        ...this.state.story_info,
        fileType: data,
      },
    });
  };

  handleLevelSelector = (val) => {
    let newLevelData = this.state.levelData.map((level) => {
      return { ...level, clicked: false };
    });
    this.setState({
      levelData: newLevelData,
      readingSelector: false,
      storyTypeSelector: false,
      fileTypeSelector: false,
      levelSelector: val,
    });
  };
  handleReadingSelector = (val) => {
    this.setState({
      levelSelector: false,
      storyTypeSelector: false,
      fileTypeSelector: false,
      readingSelector: val,
    });
  };

  handleStorySelector = (val) => {
    this.setState({
      levelSelector: false,
      readingSelector: false,
      fileTypeSelector: false,
      storyTypeSelector: val,
    });
  };
  handleFileSelector = (val) => {
    this.setState({
      levelSelector: false,
      readingSelector: false,
      storyTypeSelector: false,
      fileTypeSelector: val,
    });
  };

  handleNameChange = (evt) => {
    this.setState({
      story_info: { ...this.state.story_info, name: evt.target.value },
    });
  };
  handleAuthorNameChange = (evt) => {
    this.setState({
      story_info: { ...this.state.story_info, author_name: evt.target.value },
    });
  };
  handleDescriptionChange = (evt) => {
    this.setState({
      story_info: { ...this.state.story_info, description: evt.target.value },
    });
  };

  handleSourceChange = (evt) => {
    this.setState({
      story_info: { ...this.state.story_info, source: evt.target.value },
    });
    // console.log('story', this.state.story_info.source);
  };
  handleViewChange= () => {
    this.setState({
      story_info: { ...this.state.story_info, landscape:  !this.state.story_info.landscape},
    });
    // console.log('story', this.state.story_info.source);
  };

  handleCreateBtn = () => {
    let state = this.state;
    let str = "";
    //FREAD-1184
    str+= state.story_info.image===""?"Upload Image, ":"";
    str+= state.story_info.background_image===""?"Upload Background Image, ":"";
    str += state.selectedLevels.length === 0 ? "select levels, " : "";
    str +=
      state.story_info.author_name.length <= 0 ? "enter author name, " : "";
    str +=
      state.story_info.story_type.length === 0 ? "Select story type, " : "";
    str += state.story_info.name === "" ? "enter story name" : "";
    if (str === "") {
      this.props.story.requestCreateStory({
        ...this.state.story_info,
        grade_level: this.state.selectedLevels,
        story_type: this.state.story_info.story_type.toLowerCase(),
      });
      swal("Done", "Story created successfully!", "success");
      this.props.ui.hideModal();
    } else {
      errorHandler("Please " + str);
    }
  };

  render() {
    const readingTimeData = [
      "Less than 2",
      "2-5 ",
      "5-10 ",
      "10-15",
      "15-20 ",
      "20-25 ",
      "25-30 ",
      "More than 30",
    ];
    const storyTypeData = ["Read", "Listen", "Watch", "Sectioned"];

    const readingList = readingTimeData.map((reading, index) => (
      <a key={index} onClick={() => this.handleReadingTime(reading)}>
        {reading}
      </a>
    ));
    const storyTypeList = storyTypeData.map((story, index) => (
      <a key={index} onClick={() => this.handleStoryType(story)}>
        {story}
      </a>
    ));
    return (
      <CreateActivityContainer>
        <StoryImagesContainer>
          {this.state.story_info.background_image === "" ? (
            <UploadActivityImageContainer>
              <ActivityUploadImageButton type="button">
                <img
                  alt="cloud-full-screen-upload"
                  src={require("static/img/cloud-upload-icon.png")}
                />
                <span>Upload Full screen Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableBackgroundLoad ? (
            <UploadActivityImageContainer>
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              onMouseOver={() => this.handleBackgroundImageFocus("over")}
              onMouseOut={() => this.handleBackgroundImageFocus("out")}
            >
              <ImageContainer src={this.state.story_info.background_image} />
              <ChangeImageButton
                top={this.state.imageBackgroundChange ? "83%" : "100%"}
                opacity={this.state.imageBackgroundChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
          {this.state.story_info.image === "" ? (
            <UploadActivityImageContainer height="140px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <img
                  alt="cloud-upload"
                  src={require("static/img/cloud-upload-icon.png")}
                />
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="140px" marginBottom="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="140px"
              marginBottom="14px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.story_info.image} />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
        </StoryImagesContainer>

        <StoryCreationMainForm>
          <StoryInputContainer style={{ marginBottom: "6px" }}>
            <DropDown
              minWidth="160px"
              active={this.state.levelSelector}
              onBlur={() => this.handleLevelSelector(false)}
              tabIndex="1"
            >
              <div
                style={{ fontSize: "14px", height: "24px" }}
                onClick={() =>
                  this.handleLevelSelector(!this.state.levelSelector)
                }
              >
                {this.state.selectedLevels.length >= 18
                  ? "All "
                  : this.state.selectedLevels.length + " "}{" "}
                Levels Selected
                <Chevron>
                  <img
                    src={require("static/img/downarrowicon.png")}
                    alt="down-arrow"
                  />
                </Chevron>
              </div>
              <DropDownMenu
                maxHeight="150px"
                active={this.state.levelSelector}
                color="#fff"
                style={{ padding: "0px" }}
              >
                {this.state.levelData.map((levelData, index) => (
                  <DropdownItem>
                    <FlexStart
                      style={{
                        width: "100%",
                        padding: "6px 8px",
                        position: "relative",
                        borderBottom: "solid 1px #e6e6e6",
                      }}
                      onClick={() => this.handleLevelChange(levelData.name)}
                    >
                      <CheckBall
                        onClick={(evt) =>
                          //FREAD-1182
                          (levelData.name === "Select All" || levelData.name === "Unselect All")?
                          this.handleLevelChange(levelData.name)
                          :this.handleSpecLevelSelector(evt, levelData)
                        }
                        style={{ marginRight: "6px" }}
                        active={levelData.selected || levelData.clicked}
                      >
                        <CheckBallInner
                          active={levelData.selected || levelData.clicked}
                        />
                      </CheckBall>
                      {levelData.levels.length > 0 && (
                        <Chevron
                          cross={
                            levelData.clicked
                              ? "rotate(0deg)"
                              : "rotate(-90deg)"
                          }
                        >
                          <img
                            src={require("static/img/downarrowicon.png")}
                            alt="down-arrow"
                          />
                        </Chevron>
                      )}

                      {levelData.name}
                    </FlexStart>
                    {levelData.clicked &&
                      levelData.levels.length > 0 &&
                      levelData.levels.map((subLevel, i) => (
                        <SubLevelItem
                          key={i}
                          style={{
                            backgroundColor: "#faf7ff",
                            width: "100%",
                            padding: "6px 8px 6px 32px",
                            justifyContent: "flex-start",
                            borderBottom: "solid 1px #e6e6e6",
                          }}
                          onClick={() => {
                            this.handleSubLevelSelector(
                              levelData.name,
                              subLevel
                            );
                          }}
                        >
                          <CheckBall
                            style={{ marginRight: "6px" }}
                            active={subLevel.selected}
                          >
                            <CheckBallInner active={subLevel.selected} />
                          </CheckBall>{" "}
                          {subLevel.name}
                        </SubLevelItem>
                      ))}
                  </DropdownItem>
                ))}
              </DropDownMenu>
            </DropDown>
            <DropDown
              style={{ marginLeft: "14px" }}
              minWidth="160px"
              active={this.state.readingSelector}
              onBlur={() => this.handleReadingSelector(false)}
              tabIndex="1"
            >
              <div
                style={{ fontSize: "14px", height: "24px" }}
                onClick={() =>
                  this.handleReadingSelector(!this.state.readingSelector)
                }
              >
                {this.state.story_info.reading_time.length > 0
                  ? this.state.story_info.reading_time
                  : "Reading time (mins)"}
                <Chevron>
                  <img
                    src={require("static/img/downarrowicon.png")}
                    alt="down-arrow"
                  />
                </Chevron>
              </div>
              <DropDownMenu
                maxHeight="100px"
                active={this.state.readingSelector}
              >
                {readingList}
              </DropDownMenu>
            </DropDown>
          </StoryInputContainer>
          <StoryInputField
            type="text"
            placeholder="Author name"
            style={{ margin: "8px 0px" }}
            maxLength={30}
            padding="2px 10px"
            value={this.state.story_info.author_name || ""}
            onChange={this.handleAuthorNameChange}
          />
          <StoryInputField
            type="text"
            placeholder="Add story source"
            padding="2px 10px"
            value={this.state.story_info.source || ""}
            onChange={this.handleSourceChange}
          />
          <StoryInputContainer style={{ margin: "8px 0px" }}>
            <DropDown
              minWidth="120px"
              style={{ width: "160px", margin: "4px 0px" }}
              active={this.state.storyTypeSelector}
              onBlur={() => this.handleStorySelector(false)}
              tabIndex="1"
            >
              <div
                onClick={() =>
                  this.handleStorySelector(!this.state.storyTypeSelector)
                }
              >
                {this.state.story_info.story_type.length === 0
                  ? "Select Story type"
                  : this.state.story_info.story_type}
                <Chevron>
                  <img
                    src={require("static/img/downarrowicon.png")}
                    alt="down-arrow"
                  />
                </Chevron>
              </div>
              <DropDownMenu
                maxHeight="100px"
                active={this.state.storyTypeSelector}
              >
                {storyTypeList}
              </DropDownMenu>
            </DropDown>
          </StoryInputContainer>
          <PublishContainer>
            <CheckBox active={this.state.story_info.landscape} onClick={() =>this.handleViewChange()}>
                <i className="fa fa-check" />
              </CheckBox>
              <span style={{color:"black"}}>
                {" "}Landscape{" "}
              </span>
          </PublishContainer>
          <ActivityInputField
            type="text"
            placeholder="Add Story title here"
            maxLength={60}
            value={this.state.story_info.name || ""}
            onChange={this.handleNameChange}
          />

          <div>
            <ActivityDescription
              style={{ paddingLeft: "6px", height: "80px" }}
              placeholder="Add description"
              value={this.state.story_info.description || ""}
              onChange={this.handleDescriptionChange}
            />
          </div>
          <ThemeButton
            primary
            style={{ width: "150px" }}
            onClick={this.handleCreateBtn}
          >
            {" "}
            Create Story{" "}
          </ThemeButton>
        </StoryCreationMainForm>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  story,
  level,
})(StoryCreationForm)
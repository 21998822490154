import React, { Component } from "react";
import { connectStore } from "redux-box";
import InfiniteScroll from "react-infinite-scroller";
import { module as notification } from "store/notification";
import { module as ui } from "store/ui";
import { module as level } from "store/level";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown,
  DropdownItem,
  DropDownMenu,
  FlexBetween,
  FlexStart,
  Heading,
  InfiniteScrollLoader,
  SelectBox,
  SelectorButton,
  SubHeading,
  SubLevelItem,
  ThemeButton
} from "static/styled/custom.styled";
import {
  DropdownError,
  DropdownFlexStart,
  NotificationDropdownParentContainer,
  DoneButtonContainer,
  NotificationParentContainer,
  NotificationScrollParentContainer,
  NotificationSearchInputContainer,
  ChildDataDescription
} from "../NotificationSelectorModal.styled";
import ReactDOM from "react-dom";
import swal from "sweetalert2";


class FreadomDropdownContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      levelData: [],
      enableAllSelected: false,
      subLevelSelector: false,
      activeLevel: "",
      selectedType: "Grade",
      selectedChildren: [],
      selectedFirstChild: "",
      selectedLevels: [],
      selectedGrades: [],
      gradeTypeSelector: false,
      levelTypeSelector: false,
      childTypeSelector: false,
      childErr: "",
      childSelected: ""
    };
  }

  // USED FOR SETTING THE LEVELS IN THE MUTIDROPDOWN LIST WHEN componentDidMount function is called
  setLevelData = () => {
    let { notification } = this.props;
    let levelData = [];
    if (notification.selected_levels.length > 0)
      if (notification.selected_levels.length === 0) {
        for (let i = 0; i < this.props.level.levels.length + 1; i++)
          levelData = levelData.concat({ clicked: false, levels: [] });
        this.setState({
          levelData: levelData
        });
      } else if (notification.selected_levels.length === this.props.level.levels.length * 2) {
        levelData = [{ clicked: "false", levels: [] }];
        for (let i = 0; i < this.props.level.levels.length + 1; i++)
          levelData = levelData.concat({
            clicked: false,
            levels: ["Easy", "Hard"]
          });
        this.setState({
          levelData: levelData
        });
      } else {
        let selectedLevelsData = notification.selected_levels;
        let levelData = [{ levels: [], cliked: false }];
        for (let i = 1; i < this.props.level.levels.length + 1; i++) {
          let levels = [];
          let activeLevel = "Level " + i;
          selectedLevelsData.forEach(level => {
            let selectedLevel = level.split(":");
            console.log(selectedLevel);
            if (selectedLevel[0] === activeLevel) {
              levels.push(selectedLevel[1]);
            }
          });

          levelData = levelData.concat({ levels: levels });
        }

        this.setState({
          levelData: levelData
        });
      }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleFilterClick);
    let { notification } = this.props;
    let levelData = [];
    for (let i = 0; i < this.props.level.levels.length + 1; i++)
      levelData = levelData.concat({ clicked: false, levels: [] });
    this.setState({
      levelData: levelData,
      selectedType: notification.selected_filter
    });

    if (notification.selected_filter === "Level") {
      this.setState({
        selectedLevels: notification.selected_levels
      });
      this.setLevelData();
    } else if (notification.selected_filter === "Grade") {
      if (
        notification.selected_grades &&
        notification.selected_grades.length > 0
      ) {
        this.setState({
          selectedGrades: notification.selected_grades
        });
      }
    }
    // TO SET THE CHILDREN DROPDOWN DATA
    if (notification.selected_users && notification.selected_users.length > 0) {
      let filter =
        notification.selected_filter === "Level"
          ? "freadom-level-sublevel"
          : "freadom-grade";
      let active_notification = notification.active_notification;
      let filterIds =
        notification.selected_filter === "Level"
          ? notification.selected_levels
          : notification.selected_grades;
      if (
        (active_notification && active_notification.filter_type === filter) ||
        active_notification === null
      )
        notification.requestAllChildrenByLevel({
          filter: filter,
          levels: filterIds,
          offset: 0,
          concat: false
        });

      // CASE 1 WE HAVE ALL CHILDREN SELECTED
      if (
        notification.selected_users.length > 0 &&
        notification.selected_users[0] === "All"
      ) {
        if (
          notification.active_notification &&
          notification.active_notification.filter_type !== filter
        ) {
          this.setState({
            enableAllSelected: false,
            childSelected: ""
          });
        } else {
          this.setState({
            enableAllSelected: true,
            childSelected: "All"
          });
        }
      } else {
        if (
          notification.active_notification &&
          notification.active_notification.filter_type !== filter
        ) {
          this.setState({
            selectedChildren: []
          });
        } else
          this.setState({
            selectedChildren: notification.selected_users,
            selectedGrades: notification.selected_grades,
            selectedLevels: notification.selected_levels
          });
      }
    } else {
      this.setState({
        selectedChildren: []
      });
    }
    if (notification.grade_list && notification.grade_list.length === 0) {
      notification.requestGradesList();
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleFilterClick);
    this.props.notification.unsetChildrenListResult();
    this.props.notification.unsetChildrenList();
    this.props.notification.setEnableScroll(false);
  };

  handleFilterClick = evt => {
    const area = ReactDOM.findDOMNode(this.refs.area);

    if (!area.contains(evt.target) && this.state.childTypeSelector) {
      //console.log(area);
      this.setState({
        childTypeSelector: false
      });
    }
  };

  fetchMoreChildren = () => {
    //let {story} =this.props;
    //let {storiesResult}=this.props.story.stories_result;
    if (
      this.props.notification.children_list_result !== null &&
      this.props.notification.children_list_result.has_next &&
      this.state.childTypeSelector &&
      !this.props.notification.enableScroll
    ) {
      setTimeout(() => {
        if (
          this.props.notification.children_list_result !== null &&
          this.props.notification.children_list_result.next_page > 0
        )
          if (this.state.selectedType === "Grade")
            this.props.notification.requestAllChildrenByLevel({
              filter: "freadom-grade",
              levels: this.state.selectedGrades,
              offset: this.props.notification.children_list_result.next_page,
              concat: true
            });
          else {
            this.props.notification.requestAllChildrenByLevel({
              filter: "freadom-level-sublevel",
              levels: this.state.selectedLevels,
              offset: this.props.notification.children_list_result.next_page,
              concat: true
            });
          }
        //this.props.notification.unsetChildrenListResult();
      }, 300);
    }
  };

  handleChildTypeSelector = value => {
    let err = "";

    this.setState({
      childTypeSelector: value
    });

    if (
      this.state.selectedLevels.length === 0 &&
      this.state.selectedType === "Level"
    ) {
      err = "levels";
      this.setState({
        childTypeSelector: false,
        childErr: "Please select " + err + " first!"
      });
    } else if (
      this.state.selectedGrades.length === 0 &&
      this.state.selectedType === "Grade"
    ) {
      err = "grades";
      this.setState({
        childTypeSelector: false,
        childErr: "Please select " + err + " first!"
      });
    }
  };

  handleGradeTypeSelector = value => {
    this.setState({
      gradeTypeSelector: value
    });
    if (value && this.state.selectedLevels.length > 0) {
      this.setLevelData();
      this.setState({
        selectedLevels: []
      });
    }

    if (this.state.childErr !== "" && this.state.selectedLevels.length > 0)
      this.setState({
        childErr: ""
      });

    if (!value && this.state.selectedGrades.length > 0) {
      let { notification } = this.props;
      notification.requestAllChildrenByLevel({
        levels: this.state.selectedGrades,
        filter: "freadom-grade",
        offset: 0,
        concat: false
      });
    }
  };

  setLevelData = () => {
    let levelData = [];
    for (let i = 0; i < this.props.level.levels.length + 1; i++)
      levelData = levelData.concat({ clicked: false, levels: [] });
    this.setState({
      levelData: levelData
    });
  };

  handleGradeChange = (index, data) => {
    let { notification } = this.props;
    if (index === 0) {
      if (this.state.selectedGrades.length === 6) {
        this.setState({
          selectedGrades: []
        });
      } else {
        let selectedGrades = [];
        notification.grade_list &&
          notification.grade_list.length > 0 &&
          notification.grade_list.forEach(grade => {
            selectedGrades = selectedGrades.concat(grade.id);
          });
        this.setState({
          selectedGrades: selectedGrades
        });
      }
    } else {
      if (!this.state.selectedGrades.some(grade => grade === data.id)) {
        // console.log("hello");
        this.setState({
          selectedGrades: this.state.selectedGrades.concat(data.id)
        });
      } else {
        let selectedGrades = this.state.selectedGrades.filter(grade => {
          return grade !== data.id;
        });
        this.setState({
          selectedGrades: selectedGrades
        });
      }
    }
  };

  // Level Dropdown Functions

  handleLevelTypeSelector = value => {
    this.setState({
      levelTypeSelector: value
    });
    if (this.state.childErr !== "" && this.state.selectedLevels.length > 0)
      this.setState({
        childErr: ""
      });

    if (!value && this.state.selectedLevels.length > 0) {
      let { notification } = this.props;
      notification.requestAllChildrenByLevel({
        levels: this.state.selectedLevels,
        filter: "freadom-level-sublevel",
        offset: 0,
        concat: false
      });
    }
  };

  handleLevelChange = index => {
    let selectedLevels = [];
    if (index === 0) {
      let levelData = [{ levels: [] }];
      if (this.state.selectedLevels.length < this.props.level.levels.length * 2) {
        for (let i = 1; i < this.state.levelData.length; i++) {
          selectedLevels.push("Level " + i + ":Easy");
          selectedLevels.push("Level " + i + ":Hard");
          levelData = levelData.concat({ levels: ["Easy", "Hard"] });
        }
        this.setState({
          selectedLevels: selectedLevels,
          levelData: levelData
        });
      } else {
        let levelData = [];
        for (let i = 0; i < this.props.level.levels.length + 1; i++)
          levelData = levelData.concat({ clicked: false, levels: [] });
        this.setState({
          selectedLevels: [],
          levelData: levelData
        });
      }
    } else {
      let levelData = this.state.levelData.map((level, checkindex) => {
        if (index === checkindex) return { ...level, clicked: !level.clicked };
        return level;
      });
      this.setState({
        levelData: levelData,
        subLevelSelector: !this.state.subLevelSelector,
        activeLevel: "Level " + index
      });
    }
  };

  handleSpecLevelChange = (selectedLevelData, selectedIndex, evt) => {
    if (selectedIndex === 0) {
      this.handleLevelChange(selectedIndex);
    } else {
      let selectedLevels = this.state.selectedLevels;
      let levelName = "Level " + selectedIndex;
      let subLevelsList = ["Easy", "Hard"];
      let newLevelData = this.state.levelData.map((level, index) => {
        if (index === selectedIndex && index !== 0) {
          let levels = level.levels;
          subLevelsList.forEach(subLevel => {
            if (
              selectedLevels &&
              selectedLevels.some(data => data === levelName + ":" + subLevel)
            ) {
              selectedLevels = selectedLevels.filter(selectedLevel => {
                return selectedLevel !== levelName + ":" + subLevel;
              });
              levels = levels.filter(lev => {
                return lev !== subLevel;
              });
            } else {
              if (
                !this.state.selectedLevels.some(
                  data => data === levelName + ":" + subLevel
                )
              ) {
                selectedLevels = selectedLevels.concat(
                  levelName + ":" + subLevel
                );
                levels = levels.concat(subLevel);
              }
            }
          });

          return { ...level, levels: levels };
        }

        return level;
      });
      this.setState({
        levelData: newLevelData,
        selectedLevels: selectedLevels
      });
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  handleSubLevelSelected = (subLevelOption, selectedIndex) => {
    let levelName = "Level " + selectedIndex;
    let subLevelList = ["Easy", "Hard"];
    let newLevelData = this.state.levelData.map((level, index) => {
      if (selectedIndex === index && index !== 0) {
        let levels = level.levels;
        subLevelList.forEach(subLevel => {
          if (subLevelOption === subLevel) {
            //console.log(this.state.selectedLevels);
            if (level.levels.includes(subLevel)) {
              let selectedLevels = this.state.selectedLevels.filter(
                selectedLevel => {
                  return selectedLevel !== levelName + ":" + subLevel;
                }
              );
              levels = levels.filter(lev => {
                return lev !== subLevel;
              });

              this.setState({
                selectedLevels: selectedLevels
              });
            } else {
              this.setState({
                selectedLevels: this.state.selectedLevels.concat(
                  levelName + ":" + subLevel
                )
              });
              levels = levels.concat(subLevel);
            }
          }
        });

        return { ...level, levels: levels };
      }
      return level;
    });
    this.setState({
      levelData: newLevelData
    });
  };
  handleSelectedLevels = () => {
    this.handleLevelTypeSelector(!this.state.levelTypeSelector);
    if (this.state.selectedLevels.length === this.props.level.levels.length * 2) {
      let levelData = [{ levels: [] }];
      for (let i = 1; i <= 6; i++) {
        levelData = levelData.concat({ levels: ["Easy", "Hard"] });
      }
      this.setState({
        levelData: levelData
      });
    } else if (this.state.selectedLevels.length > 0) {
      let selectedLevelsData = this.state.selectedLevels.sort();
      let levelData = [{ levels: [] }];
      for (let i = 1; i < this.props.level.levels.length + 1; i++) {
        let levels = [];
        let activeLevel = "Level " + i;
        selectedLevelsData.forEach(level => {
          let selectedLevel = level.split(":");
          console.log(selectedLevel);
          if (selectedLevel[0] === activeLevel) {
            levels.push(selectedLevel[1]);
          }
        });

        levelData = levelData.concat({ levels: levels });
      }

      this.setState({
        levelData: levelData
      });
    }
  };

  handleSelectedType = data => {
    if (data !== this.state.selectedType) {
      this.props.notification.setSelectedFilter(data);
      this.props.notification.unsetChildrenList();
      this.setState({
        selectedType: data,
        selectedChildren: "",
        enableSelected: false,
        childSelected: "",
        childErr: ""
      });
      if (data === "Level")
        this.setState({
          selectedGrades: []
        });
      else {
        this.setState({
          selectedLevels: []
        });
      }
    }
  };

  handleChildChange = (index, data) => {
    if (index === 0) {
      // console.log(this.state.childSelected);
      if (this.state.childSelected === "" || !this.state.enableAllSelected) {
        let { notification } = this.props;
        let selectedChildren = [];
        if (
          notification.children_list.length > 0 &&
          notification.search_child !== ""
        ) {
          console.log("hello");
          notification.children_list.forEach((child, index) => {
            if (index === 0)
              this.setState({
                selectedFirstChild: child.name
              });
            selectedChildren = selectedChildren.concat(child.id);
          });
          this.setState({
            selectedChildren: selectedChildren
          });
        } else
          this.setState({
            selectedChildren: ["All"],
            childSelected: "All",
            enableAllSelected: true
          });
      } else {
        this.setState({
          childSelected: "",
          enableAllSelected: false,
          selectedFirstChild: "",
          selectedChildren: []
        });
      }
    } else {
      if (
        this.state.selectedChildren.length === 0 ||
        this.state.selectedChildren[0] === "All"
      ) {
        let selectedChild = [data.id];
        this.setState({
          selectedFirstChild: data.name,
          selectedChildren: selectedChild,
          enableAllSelected: false,
          childSelected: ""
        });
      } else if (!this.state.selectedChildren.some(child => child === data.id))
        // console.log("hello");

        this.setState({
          selectedChildren: this.state.selectedChildren.concat(data.id)
        });
      else if (this.state.selectedChildren.length > 0) {
        let count = 0;
        let selectedChildren = this.state.selectedChildren.filter(child => {
          if (child !== data.id && count === 0) {
            this.setState({ selectedFirstChild: child.name });
            count++;
          }
          return child !== data.id;
        });
        this.setState({
          selectedChildren: selectedChildren,

          enableAllSelected: false,
          childErr: ""
        });
      }
    }
  };

  handleUpdateNotification = (selected_list, filter_type) => {
    let { notification } = this.props;

    let notification_data = {
      filter_type: filter_type,
      filter_type_ids: selected_list,
      custom_user_ids: [],
      wow_school_ids: []
    };
    swal({
      title: "Are you sure?",
      text: "You want to make changes in the notification criteria!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        if (
          this.state.selectedChildren.length > 0 &&
          this.state.selectedChildren[0] !== "All"
        ) {
          notification.requestUpdateNotification({
            ...notification_data,
            custom_user_ids: this.state.selectedChildren
          });
        } else {
          notification.requestUpdateNotification(notification_data);
        }
        notification.unsetDropdownData();
      }
      this.props.ui.hideNotificationModal();
    });
  };

  handleErrorCheck = type => {
    let err = "";
    if (type === "Level") {
      if (this.state.selectedLevels.length === 0) err += "levels";
    } else {
      if (this.state.selectedGrades.length === 0) err += "grades";
    }
    if (this.state.selectedChildren.length === 0) err += ", children";

    return err;
  };
  handleDoneButton = () => {
    let { notification, ui } = this.props;
    let err = this.handleErrorCheck(this.state.selectedType);

    if (this.state.selectedType === "Level" && err === "") {
      if (notification.active_notification === null) {
        notification.setSelectedFirstChild(this.state.selectedFirstChild);
        notification.setSelectedLevelsList(this.state.selectedLevels);
        if (this.state.enableAllSelected) {
          notification.setSelectedUsersList(["All"]);
          notification.setSelectedFreadomData(
            "All Children, " + this.state.selectedLevels.length + " Levels"
          );
        } else {
          notification.setSelectedUsersList(this.state.selectedChildren);
          let selectedChild = "";
          if (this.state.selectedChildren.length > 1)
            selectedChild =
              this.state.selectedFirstChild +
              "+" +
              this.state.selectedChildren.length -
              1;
          else selectedChild = this.state.selectedFirstChild;
          notification.setSelectedFreadomData(
            selectedChild + "," + this.state.selectedLevels.length + " Levels"
          );
        }
        ui.hideNotificationModal();
      } else {
        this.handleUpdateNotification(
          this.state.selectedLevels,
          "freadom-level-sublevel"
        );
      }
    } else if (this.state.selectedType === "Grade" && err === "") {
      if (notification.active_notification === null) {
        notification.setSelectedFirstChild(this.state.selectedFirstChild);
        notification.setSelectedGradeList(this.state.selectedGrades);
        if (this.state.enableAllSelected) {
          notification.setSelectedUsersList(["All"]);
          notification.setSelectedFreadomData(
            "All Children, " + this.state.selectedGrades.length + " Grades"
          );
        } else {
          notification.setSelectedUsersList(this.state.selectedChildren);
          let selectedChild = "";
          if (this.state.selectedChildren.length > 1)
            selectedChild =
              this.state.selectedFirstChild +
              "+" +
              this.state.selectedChildren.length -
              1;
          else selectedChild = this.state.selectedFirstChild;
          notification.setSelectedFreadomData(
            selectedChild + "," + this.state.selectedGrades.length + " Grades"
          );
        }
        ui.hideNotificationModal();
      } else {
        this.handleUpdateNotification(
          this.state.selectedGrades,
          "freadom-grade"
        );
      }
    } else {
      swal("Oops!", "Please select " + err + " !", "warning");
    }
  };

  handleChildListSearch = evt => {
    let { notification } = this.props;

    notification.setChildrenListSearch(evt.target.value);
    // if (evt.target.value !== '') {
    //     this.setState({
    //         enableSelected: false,
    //         childSelected: '',
    //         childErr: '',
    //         selectedFirstChild: '',
    //         selectedChildren:[],
    //     })}

    if (
      this.state.selectedLevels.length > 0 &&
      this.state.selectedType === "Level"
    ) {
      setTimeout(() => {
        this.props.notification.requestAllChildrenByLevel({
          filter: "freadom-level-sublevel",
          levels: this.state.selectedLevels,
          offset: 0,
          concat: false
        });
      }, 1000);
    } else if (
      this.state.selectedGrades.length > 0 &&
      this.state.selectedType === "Grade"
    ) {
      setTimeout(() => {
        this.props.notification.requestAllChildrenByLevel({
          filter: "freadom-grade",
          levels: this.state.selectedGrades,
          offset: 0,
          concat: false
        });
      }, 1000);
    }
  };

  render() {
    let categoryData = ["Grade", "Level"];
    let { notification } = this.props;
    let { state } = this;
    return (
      <NotificationParentContainer>
        <Heading> Select Filter By </Heading>
        <FlexBetween
          style={{ marginTop: "10px", justifyContent: "flex-start" }}
        >
          {categoryData.map((data, index) => (
            <SelectBox
              key={index}
              active={this.state.selectedType === data}
              onClick={() => this.handleSelectedType(data)}
              style={{ marginRight: "15px" }}
            >
              <SelectorButton active={this.state.selectedType === data}>
                <div />
              </SelectorButton>
              <span>{data}</span>
            </SelectBox>
          ))}
        </FlexBetween>
        {/*If Condition for either displaying grade or level*/}
        {this.state.selectedType === "Grade" ? (
          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}>Grade </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                active={this.state.gradeTypeSelector}
                onBlur={() => this.handleGradeTypeSelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "14px", height: "24px" }}
                  onClick={() =>
                    this.handleGradeTypeSelector(!this.state.gradeTypeSelector)
                  }
                >
                  {state.selectedGrades !== null &&
                  this.state.selectedGrades.length === 0
                    ? "None Selected "
                    : this.state.selectedGrades.length === 6
                      ? "All Grades Selected"
                      : this.state.selectedGrades.length + " Grades Selected"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="160px"
                  active={this.state.gradeTypeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  <DropdownItem>
                    <DropdownFlexStart
                      style={{
                        width: "100%",
                        padding: "6px 8px",
                        position: "relative",
                        borderBottom: "solid 1px #e6e6e6"
                      }}
                      onClick={() => this.handleGradeChange(0, "All")}
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={this.state.selectedGrades.length === 6}
                      >
                        <CheckBallInner
                          second
                          active={this.state.selectedGrades.length === 6}
                        />
                      </CheckBall>
                      {this.state.selectedGrades.length === 6
                        ? "Unselect All"
                        : "Select All"}
                    </DropdownFlexStart>
                  </DropdownItem>
                  {notification.grade_list &&
                    notification.grade_list.length > 0 &&
                    notification.grade_list.map((grade, index) => (
                      <DropdownItem key={index + 1}>
                        <DropdownFlexStart
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            position: "relative",
                            borderBottom: "solid 1px #e6e6e6"
                          }}
                          onClick={() =>
                            this.handleGradeChange(index + 1, grade)
                          }
                        >
                          <CheckBall
                            second
                            style={{ marginRight: "6px" }}
                            active={this.state.selectedGrades.includes(
                              grade.id
                            )}
                          >
                            <CheckBallInner
                              second
                              active={this.state.selectedGrades.includes(
                                grade.id
                              )}
                            />
                          </CheckBall>
                          {grade.name}
                        </DropdownFlexStart>
                      </DropdownItem>
                    ))}
                </DropDownMenu>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
        ) : (
          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}> Level </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                active={this.state.levelTypeSelector}
                onBlur={() => this.handleLevelTypeSelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "14px", height: "24px" }}
                  onClick={this.handleSelectedLevels}
                >
                  {this.state.selectedLevels.length === 0
                    ? "None Selected "
                    : this.state.selectedLevels.length >= this.props.level.levels.length * 2
                      ? "All Levels Selected"
                      : this.state.selectedLevels.length + " Levels Selected"}

                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="160px"
                  active={this.state.levelTypeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.state.levelData.map((levelData, index) => (
                    <DropdownItem key={index}>
                      <FlexStart
                        style={{
                          width: "100%",
                          padding: "6px 8px",
                          position: "relative",
                          borderBottom: "solid 1px #e6e6e6"
                        }}
                        onClick={() => this.handleLevelChange(index)}
                      >
                        <CheckBall
                          second
                          onClick={evt =>
                            this.handleSpecLevelChange(levelData, index, evt)
                          }
                          style={{ marginRight: "6px" }}
                          active={
                            levelData.levels.length > 0 ||
                            this.state.selectedLevels.length === this.props.level.levels.length * 2
                          }
                        >
                          <CheckBallInner
                            second
                            active={
                              levelData.levels.length > 0 ||
                              this.state.selectedLevels.length === this.props.level.levels.length * 2
                            }
                          />
                        </CheckBall>
                        {index === 0
                          ? this.state.selectedLevels.length === this.props.level.levels.length * 2
                            ? "Unselect All"
                            : "Select All"
                          : "Level " + index}
                        {index !== 0 && (
                          <Chevron
                            cross={
                              this.state.activeLevel === "Level " + index &&
                              this.state.subLevelSelector
                                ? "rotate(0deg)"
                                : "rotate(-90deg)"
                            }
                          >
                            <img
                              src={require("static/img/downarrowicon.png")}
                              alt="down-arrow"
                            />
                          </Chevron>
                        )}

                        {levelData.name}
                      </FlexStart>
                      {this.state.activeLevel === "Level " + index &&
                      index !== 0 &&
                      levelData.clicked ? (
                        <div>
                          <SubLevelItem
                            style={{
                              backgroundColor: "#faf7ff",
                              width: "100%",
                              padding: "6px 8px 6px 32px",
                              justifyContent: "flex-start",
                              borderBottom: "solid 1px #e6e6e6"
                            }}
                            onClick={() => {
                              this.handleSubLevelSelected("Easy", index);
                            }}
                          >
                            <CheckBall
                              second
                              style={{ marginRight: "6px" }}
                              active={levelData.levels.includes("Easy")}
                            >
                              <CheckBallInner
                                second
                                active={levelData.levels.includes("Easy")}
                              />
                            </CheckBall>{" "}
                            Easy
                          </SubLevelItem>
                          <SubLevelItem
                            style={{
                              backgroundColor: "#faf7ff",
                              width: "100%",
                              padding: "6px 8px 6px 32px",
                              justifyContent: "flex-start",
                              borderBottom: "solid 1px #e6e6e6"
                            }}
                            onClick={() => {
                              this.handleSubLevelSelected("Hard", index);
                            }}
                          >
                            <CheckBall
                              second
                              style={{ marginRight: "6px" }}
                              active={levelData.levels.includes("Hard")}
                            >
                              <CheckBallInner
                                second
                                active={levelData.levels.includes("Hard")}
                              />
                            </CheckBall>{" "}
                            Hard
                          </SubLevelItem>
                        </div>
                      ) : null}
                    </DropdownItem>
                  ))}
                </DropDownMenu>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
        )}

        <NotificationDropdownParentContainer>
          <SubHeading style={{ margin: 0 }}> Child </SubHeading>
          <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
            <DropDown
              minWidth="180px"
              active={this.state.childTypeSelector}
              ref="area"
              tabIndex="1"
            >
              <div
                style={{ fontSize: "14px", height: "24px" }}
                onClick={() =>
                  this.handleChildTypeSelector(!this.state.gradeTypeSelector)
                }
              >
                {this.state.selectedChildren.length === 0 &&
                !this.state.enableAllSelected
                  ? "None Selected "
                  : this.state.enableAllSelected
                    ? "All Selected"
                    : this.state.selectedChildren.length + " Child Selected"}
                <Chevron>
                  <img
                    src={require("static/img/downarrowicon.png")}
                    alt="down-arrow"
                  />
                </Chevron>
              </div>

              <NotificationScrollParentContainer
                maxHeight="240px"
                active={this.state.childTypeSelector}
                color="#fff"
                style={{ padding: "0px" }}
              >
                <NotificationSearchInputContainer
                  onClick={() => this.handleChildTypeSelector(true)}
                >
                  <input
                    type="text"
                    placeholder="Type to Search"
                    value={this.props.notification.search_child}
                    onChange={this.handleChildListSearch}
                  />
                  <i className="fa fa-search" />
                </NotificationSearchInputContainer>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMoreChildren}
                  hasMore={
                    this.props.notification.children_list_result &&
                    this.props.notification.children_list_result.has_next
                  }
                  style={{ position: "relative", marginTop: "40px" }}
                  loader={
                    <InfiniteScrollLoader className="loader" key={0}>
                      <img
                        alt="loading"
                        width="20"
                        height="20"
                        src={require("static/img/Theme-loader.gif")}
                      />
                    </InfiniteScrollLoader>
                  }
                  useWindow={false}
                >
                  <DropdownItem>
                    <DropdownFlexStart
                      second
                      onClick={() => this.handleChildChange(0, "All")}
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={
                          this.state.childSelected === "All" &&
                          this.state.enableAllSelected
                        }
                      >
                        <CheckBallInner
                          second
                          active={
                            this.state.childSelected === "All" &&
                            this.state.enableAllSelected
                          }
                        />
                      </CheckBall>
                      {this.state.enableAllSelected
                        ? "Unselect All"
                        : "Select All"}
                    </DropdownFlexStart>
                  </DropdownItem>

                  {notification.children_list.map((child, index) => (
                    <DropdownItem key={index + 1}>
                      <DropdownFlexStart
                        second
                        onClick={() => this.handleChildChange(index + 1, child)}
                      >
                        <CheckBall
                          second
                          style={{ marginRight: "6px" }}
                          active={
                            this.state.selectedChildren.includes(child.id) ||
                            (this.state.childSelected === "All" &&
                              this.state.enableAllSelected)
                          }
                        >
                          <CheckBallInner
                            second
                            active={
                              this.state.selectedChildren.includes(child.id) ||
                              (this.state.childSelected === "All" &&
                                this.state.enableAllSelected)
                            }
                          />
                        </CheckBall>
                        <ChildDataDescription>
                          {child.name}
                          <div>
                            {" "}
                            {child.parent.name + "- "}
                            {this.state.selectedType === "Grade"
                              ? child.grade.name
                              : child.grade_level +
                                " (" +
                                child.level +
                                ")"}{" "}
                          </div>
                          <div>
                            {" "}
                            <i class="fa fa-phone" aria-hidden="true" />
                            {`: ${child.parent.contact_no}`}{" "}
                          </div>
                        </ChildDataDescription>
                      </DropdownFlexStart>
                    </DropdownItem>
                  ))}
                </InfiniteScroll>
              </NotificationScrollParentContainer>
            </DropDown>
          </FlexBetween>
        </NotificationDropdownParentContainer>
        {this.state.childErr !== "" && (
          <DropdownError active={this.state.childErr !== ""}>
            {this.state.childErr}
          </DropdownError>
        )}
        <DoneButtonContainer>
          <ThemeButton primary onClick={this.handleDoneButton}>
            {" "}
            Done{" "}
          </ThemeButton>
        </DoneButtonContainer>
      </NotificationParentContainer>
    );
  }
}
export default connectStore({
  notification,
  ui,
  level
})(FreadomDropdownContainer)
import React, { Component } from "react";
import GuestRouter from "./router/guestRouter";
import AppRouter from "./router/appRouter";
import { connectStore } from "redux-box";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./global.css";
import { module as onboard } from "@st/onboard";
import { module as activity } from "@st/activity";
import { module as level } from "@st/level";
import Permission from "@s/permission";

class App extends Component {
  getPath = () => {
    const { permission } = this.props.onboard;
    let expression = Permission.getFirstPermission(permission);
    let p ="";
    switch (expression) {
      case "user":
        p = "/users";
        break;
      case "activity":
        p = "/activities";
        break;
      case "reading":
        p = "/reading-challenge";
        break;
      case "news fread":
        p = "/news-freads/Level%201/Easy";
        break;
      case "story":
        p = "/stories/Level%201/Easy";
        break;
      case "tag":
        p = "/tags";
        break;
      case "quiz":
        p = "/quiz2";
        break;
      case "invite code":
        p = "/invite-code";
        break;
      case "testimonial":
        p = "/case-studies";
        break;
      case "school code":
        p = "/flive-code";
        break;
      case "notification":
        p = "/notifications/scheduled";
        break;
      case "payment":
        p = "/payments/success";
        break;
      case "story experience":
        p = "/experiences/story";
        break;
      case "reading experience":
        p = "/experiences/reading";
        break;
      case "activity experience":
        p = "/experiences/activity";
        break;
      case "school":
        p = "/school-code";
        break;
      case "superuser":
        p = "/users";
        break;
      default:
        p = "";
    }
    return p;
  };

  userLoggedIn = () => {
    if (this.props.onboard.user === null) {
      if (localStorage.getItem("user_detail")) {
        let user = localStorage.getItem("user_detail");
        this.props.onboard.setLogin(JSON.parse(user));
        this.props.activity.requestGrades();
        this.props.level.requestLevels();
      } else {
        return false;
      }
    } else if (
      this.props.location.pathname === "/login" ||
      (this.props.onboard.user &&
        localStorage.getItem("user_detail") &&
        (this.props.location.pathname === "/" ||
          this.props.location.pathname === "/index.html"))
    ) {
      this.props.history.push("/welcome");
    } else {
      // console.log('this.props.location.pathname',this.props.location.pathname,this.props.onboard.user)
      // if(this.props.onboard.user != null && this.props.location.pathname == '/'){
      //   let path = this.getPath();
      //   console.log("path",path)
      //   this.props.history.push(path)
      // }else{
      const { permission } = this.props.onboard;

      if (
        this.props.onboard.user != null &&
        localStorage.getItem("user_detail") &&
        Permission.checkRoutePermission(
          permission,
          this.props.location.pathname
        )
      ) {
        return true;
      } else {
        this.props.onboard.setLogin(null);
        localStorage.clear();
        this.props.history.push("/login");
        return false;
      }
    }
  };

  render() {
    return (
      <div className="App">
        {this.userLoggedIn() ? <AppRouter /> : <GuestRouter />}
      </div>
    );
  }
}

export default connectStore({
  onboard,
  activity,
  level,
})(App);

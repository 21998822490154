import {createSagas} from "redux-box";
import onBoardApi from "@s/api/onBoard";
import {call, put} from "redux-saga/effects";
//import {push} from "react-router-redux";
import swal from "sweetalert2";


const errorHandler = err => {
    console.log(err);
    swal("Oops!", err.error_message, "error");
};

export default createSagas({
    REQUEST_LOGIN: function* ({login}) {
        try {
            let res = yield call(onBoardApi.getLogin,login);
            res['read_only'] = false;
            localStorage.setItem('user_detail', JSON.stringify(res));
            yield put({
                type: "SET_LOGIN",
                user_detail: res
            });
            if(res.groups && res.groups.length > 0){
                yield put({
                    type: "SET_PERMISSION",
                    permission: res.groups
                });
            }
           
        } catch (err) {
            errorHandler(err);
        }
    },
   

})